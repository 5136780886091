export const api_routes = {
  /*
   * Auth Routes
   */
  auth: {
    login: "/user/auth/login",
    logout: "/user/logout",
  },
  /*
   * Profile Routes
   */
  profile: {
    findUser: "/user/find/",
    allUsers: "/user/allUsers",
    myProfile: "/user/myprofile",
    checkLogin: "/user/auth/check-login",
  },
  /*
   * Notification Routes
   */
  notification: {
    unread: "/notification/unread",
    readAll: "/notification/readAll",
    readOne: "/notification/readOne",
  },
  /*
   * Profile Routes
   */
  department: {
    list: "/user/department/list",
  },
  /*
   * Post Routes
   */
  post: {
    getAll: "/post/getallPost",
    common: "/post/common",
    add: "/post/addPost",
    hide: "/post/hide/",
    unhide: "/post/unhide/",
    getPrivate: "/post/getPrivatePosts",
    archive: "/post/archive",
    hidden: "/post/hidden",
    delete: "/post/delete/",
    update: "/post/update/",
  },
  /*
   * Like Routes
   */
  like: {
    add: "/post/addLikes",
    get: "/post/getLikes/",
    toggle: "/post/toggleLike/",
    delete: "/post/deleteLikes/",
  },
  /*
   * Comment Routes
   */
  comment: {
    all: "/post/allComment",
    add: "/post/addComment",
    delete: "/post/deleteComment/",
    update: "/post/updateComment/",
  },
  /*
   * Challenge Routes
   */
  challenge: {
    all: "/challenge",
    add: "/challenge",
    delete: "/challenge/",
    update: "/challenge/",
    assignment: "/challenge/assignment",
    userAction: "/challenge/user-action",
    assignmentCompleted: "/challenge/assignment/complete",
    assignmentValidate: "/challenge/assignment/validate",
  },
  /*
   * Reward Routes
   */
  reward: {
    create: "/reward",
    get: "/reward",
    delete: "/reward",
    update: "/reward/",
    category: {
      create: "/reward/category",
      get: "/reward/category",
      delete: "/reward/category",
    },
    sub_category: {
      create: "/reward/sub-category",
      get: "/reward/sub-category",
      delete: "/reward/sub-category",
    },
    purchase: "/reward/purchases",
    vendor: {
      list: "/vendor",
      dropDownlist: "/vendor?includeAll=true",
    },
  },

  /*
   * Reward Routes
   */
  awards: {
    issue: "/award/issue",
    get: "/award/awardpage/all",
  },

  /*
   * Leaderboard Routes
   */
  leaderboard: {
    list: "/user/points/leaderboard",
  },

  /*
   * Recognition Routes
   */
  recognition: {
    add: "/recognition/add",
  },

  /*
   * User Routes
   */
  user: {
    profile: "/user/myprofile",
    progress: "/user/userProgress",
    awards: "/award/myProfileAwards",
    skills: "/user/skillsRecognized",
    points: "/user/points",
    update: "/user/update",
  },

  /*
   * Admin Users Routes
   */

  admin: {
    adminAllUsers: "/admin/allUsers",
    organisationList: "/admin/organisationList",
    userCheckIn: "/admin/userCheckIn",
    deleteUser: "/admin/deleteUser",
    editUser: "/admin/userProfileEdit",
    impersonate: "/admin/impersonate",
    pointSummary: "/admin/getTransaction",
  },

  /*
   * Skill Routes
   */

  skill: {
    addNewSkill: "/skill/add",
    getAllSkills: "/skill/getAll",
    updateSkill: "/skill/update",
    deleteSkill: "/skill/delete",
  },

  /*
   * Event Routes
   */

  event: {
    addNewEvent: "/event/add",
    getAllEvents: "/event/all",
    updateEvent: "/event/update",
    deleteEvent: "/event/delete",
  },

  /*
   * Vendor Routes
   */

  vendor: {
    addNewVendor: "/vendor",
    getAllVendors: "/vendor",
    updateVendor: "/vendor",
    deleteVendor: "/vendor",
  },

  /*
   * SUPER_ADMIN Route
   */

  super_admin: {
    addPoints: "/admin/addPoint",
    deductPoints: "/admin/deductPoint",
    getUserPoints: "/admin/getUserPoint",
  },
};
