/*
 * Page routes list
 */

export const page_routes = {
  dashboard: "/",
  challenges: "/challenges",
  myDetail: "/my-detail",
  profile: "/profile",
  login: "/login",
  admin: "/admin/users",
  admin_skills: "/admin/skills",
  admin_events: "/admin/events",
  admin_vendors: "/admin/vendors",
  recognition: "/recognition",
  rewards: "/rewards",
  reward_categories: "/reward/categories",
  reward_sub_categories: "/reward/sub-categories",
  redemtions: "/redemtions",
  awards: "/awards",
  insufficientPermission: "/403",
};

export const page_names = {
  dashboard: {name: "Dashboard", path: page_routes.dashboard},
  challenges: {name: "Challenges", path: page_routes.challenges},
  myDetail: {name: "My Detail", path: page_routes.myDetail},
  profile: {name: "Profile", path: page_routes.profile},
  login: {name: "Login", path: page_routes.login},
  admin: {name: "Admin", path: page_routes.admin},
  admin_skills: {name: "Skills", path: page_routes.admin_skills},
  admin_events: {name: "Events", path: page_routes.admin_events},
  admin_vendors: {name: "Vendors", path: page_routes.admin_vendors},
  recognition: {name: "Recognition", path: page_routes.recognition},
  rewards: {name: "Rewards", path: page_routes.rewards},
  reward_categories: {name: "Reward Categories", path: page_routes.reward_categories},
  reward_sub_categories: {name: "Reward Sub-Categories", path: page_routes.reward_sub_categories},
  redemtions: {name: "Redemptions", path: page_routes.redemtions},
  awards: {name: "Awards", path: page_routes.awards},
  insufficientPermission: {name: "Insufficient Permission", path: page_routes.insufficientPermission},
};