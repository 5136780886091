import React, { createContext, useCallback, useContext, useState, useMemo } from "react";
import { ChildrenType, UserType} from "../helper/types";
import { getCookie, setCookie } from "react-use-cookie";

export const UserCookieName = 'user';
export const ParentUserCookieName = 'parentUser';
export const ParentUserOrgCookieName = 'parentOrgDetail';

export type ParentUserOrgType = {
  oId: string,
  oname: string
}
/*
  * User Context Type
*/
type UserContextType = {
    isAuthenticated: boolean;
    user: UserType | null;
    parentUser: UserType | null;
    parentUserOrg: ParentUserOrgType | null;
    userCookieData: UserType | undefined;
    parentUserCookieData: UserType | undefined;
    parentUserOrgCookieData: ParentUserOrgType | undefined;
    setUser: (data:UserType) => void;
    setParentUser: (data:UserType) => void;
    setParentUserOrg: (data:ParentUserOrgType) => void;
    removeUser: () => void
}

/*
  * User Context Default Value
*/
const userDefaultValues: UserContextType = {
    isAuthenticated: false,
    user: null,
    parentUser: null,
    parentUserOrg: null,
    userCookieData: undefined,
    parentUserCookieData: undefined,
    parentUserOrgCookieData: undefined,
    setUser: (data:UserType) => {},
    setParentUser: (data:UserType) => {},
    setParentUserOrg: (data:ParentUserOrgType) => {},
    removeUser: () => {}
};

/*
  * User Context
*/
const UserContext = createContext<UserContextType>(userDefaultValues);

export const useUser = () => useContext(UserContext);

/*
  * User Provider
*/
const UserProvider: React.FC<ChildrenType> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [user, setUser] = useState<UserType | null>(null);
  const [parentUser, setParentUser] = useState<UserType | null>(null);
  const [parentUserOrg, setParentUserOrg] = useState<ParentUserOrgType | null>(null);

  /*
   * Function for setting user state
   */
  const authenticatedUserHandler = useCallback((data: UserType) => {
    setCookie(UserCookieName, btoa(JSON.stringify(data)));
    setUser(data);
    setIsAuthenticated(true);
  }, []);
  
  const parentUserHandler = useCallback((data: UserType) => {
    setCookie(ParentUserCookieName, btoa(JSON.stringify(data)));
    setParentUser(data);
  }, []);
  
  const parentUserOrgHandler = useCallback((data: ParentUserOrgType) => {
    setCookie(ParentUserOrgCookieName, btoa(JSON.stringify(data)));
    setParentUserOrg(data);
  }, []);

  const removeUser = useCallback(() => {
    setCookie(UserCookieName, "");
    setCookie(ParentUserOrgCookieName, "")
    setCookie(ParentUserCookieName, "");
    setUser(null);
    setParentUser(null);
    setParentUserOrg(null);
    setIsAuthenticated(false);
  }, []);

  const fetchCookie = useCallback((value:string)=>getCookie(value),[])

  const userCookieData = useMemo(()=>{
    const cookie = fetchCookie(UserCookieName);
    if(cookie.length>0) {
      try {
        let encryptedString = atob(cookie);
        let decryptedData = JSON.parse(encryptedString) as UserType|string|null;
        if(decryptedData && typeof decryptedData !== 'string'){
          return decryptedData as UserType;
        }else{
            return undefined;
        }
      } catch (error) {
          return undefined;
      }
    }
    return undefined;
  }, [fetchCookie]);

  const parentUserCookieData = useMemo(()=>{
    const cookie = fetchCookie(ParentUserCookieName);
    if(cookie.length>0) {
      try {
        let encryptedString = atob(cookie);
        let decryptedData = JSON.parse(encryptedString) as UserType|string|null;
        if(decryptedData && typeof decryptedData !== 'string'){
          return decryptedData as UserType;
        }else{
            return undefined;
        }
      } catch (error) {
          return undefined;
      }
    }
    return undefined;
  }, [fetchCookie]);

  const parentUserOrgCookieData = useMemo(()=>{
    const cookie = fetchCookie(ParentUserOrgCookieName);
    if(cookie.length>0) {
      try {
        let encryptedString = atob(cookie);
        let decryptedData = JSON.parse(encryptedString) as {
          oId: string,
          oname: string
        }|string|null;
        if(decryptedData && typeof decryptedData !== 'string'){
          return decryptedData as {
            oId: string,
            oname: string
          };
        }else{
            return undefined;
        }
      } catch (error) {
          return undefined;
      }
    }
    return undefined;
  }, [fetchCookie]);

  return (
    <UserContext.Provider
      value={{ 
        user, 
        setUser:authenticatedUserHandler, 
        parentUser, 
        setParentUser:parentUserHandler, 
        parentUserOrg, 
        setParentUserOrg:parentUserOrgHandler, 
        isAuthenticated, 
        removeUser, 
        userCookieData, 
        parentUserCookieData, 
        parentUserOrgCookieData 
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;